<template>
  <PageCard pageName="Rerun Reports" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <b-card title="Single report rerun">
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-form-group label="Report Rerun mode">
              <b-form-radio-group
                id="single-report-rerun"
                v-model="selectedReportRerunMode"
                :options="reportRerunModes"
                name="report-rerun-options"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              label="Enter Report ID:"
              label-for="ReportId"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="ReportId"
                    type="text"
                    placeholder="Report ID ex: 12345"
                    v-model="reportId"
                  ></b-form-input>
                </div>
                <div class="col-sm-3">
                  <b-button
                    type="submit"
                    size="sm"
                    variant="primary"
                    @click="rerunReport()"
                    >Re-run</b-button
                  >
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-card>
      <b-card title="Bulk Rerun">
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-form-group label="Peer group category">
              <b-form-checkbox
                v-for="option in peerGroupCategories"
                v-model="selectedPeerGroupCategory"
                :key="option.value"
                :value="option.value"
                name="flavour-4a"
                inline
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-form-group label="Peer group Fi types">
              <b-form-checkbox
                v-for="option in fiTypes"
                v-model="selectedFiType"
                :key="option.value"
                :value="option.value"
                name="flavour-4a"
                inline
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-form-group label="Peer group Rerun mode">
              <b-form-radio-group
                id="radio-group-1"
                v-model="selectedRerunMode"
                :options="rerunModes"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-button
              type="submit"
              size="sm"
              variant="primary"
              @click="bulkRerun()"
              >Start Calculation</b-button
            >
          </div>
        </div>
      </b-card>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import PageCard from '@/modules/core/components/layouts/PageCard'
import router from '@/router'

export default {
  name: 'RerunReports',
  components: {
    PageCard
  },
  data() {
    return {
      reportId: null,
      selectedPeerGroupCategory: [],
      peerGroupCategories: [
        { text: 'Assets', value: 'assets' },
        { text: 'Focus', value: 'focus' },
        { text: 'Market', value: 'market' },
        { text: 'Custom', value: 'custom' }
      ],
      selectedFiType: [],
      fiTypes: [
        { text: 'Bank', value: 'bank' },
        { text: 'BHC', value: 'bhc' },
        { text: 'Credit Union', value: 'credit_union' }
      ],
      selectedRerunMode: 'report_only',
      rerunModes: [
        { text: 'Report only', value: 'report_only' },
        { text: 'Peer Group calculation', value: 'peer_group_only' },
        { text: 'Peer Group BankService', value: 'bank_service_only' },
        {
          text: 'Peer Group & Report BankService',
          value: 'bank_service_plus_report'
        },
        {
          text: 'Aggregate Pre-process',
          value: 'aggregate_pre_process'
        }
      ],
      selectedReportRerunMode: 'complete',
      reportRerunModes: [
        { text: 'Complete rerun', value: 'complete' },
        { text: 'Report only', value: 'report_only' },
        { text: 'Peer Group calculation', value: 'peer_group_only' },
        { text: 'Peer Group BankService', value: 'bank_service_only' },
        {
          text: 'Peer Group & Report BankService',
          value: 'bank_service_plus_report'
        },
        {
          text: 'Aggregate Pre-process',
          value: 'aggregate_pre_process'
        }
      ]
    }
  },

  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  methods: {
    rerunReport() {
      this.$http
        .get('/api/super_admin/reports/' + this.reportId + '/rerun', {
          params: {
            mode: this.selectedReportRerunMode
          },
          handleErrors: true
        })
        .then(res => {
          this.reportId = null
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    bulkRerun() {
      this.$http
        .post(
          '/api/super_admin/reports/bulk_rerun',
          {
            mode: this.selectedRerunMode,
            fi_types: this.selectedFiType,
            category: this.selectedPeerGroupCategory
          },
          {
            handleErrors: false
          }
        )
        .then(res => {
          this.reportId = null
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>
